export const makeStickyHeight = () => {
    /** @type {HTMLElement} */
    const leftPane = document.querySelector('#left-pane');
    /** @type {HTMLElement} */
    const rightPane = document.querySelector('#right-pane');
    /** @type {NodeListOf<HTMLElement>} */
    const stickyWrapper = document.querySelectorAll('.sticky-wrapper-polyfill:not(.fixed-height)');

    if (!leftPane || !rightPane || !stickyWrapper.length) {
        return;
    }

    const leftPaneHeight = leftPane.clientHeight;
    const rightPaneHeight = rightPane.clientHeight;
    const { clientHeight } = stickyWrapper[0];
    const stickyWrapperHeight =
        stickyWrapper.length > 1 ? clientHeight * stickyWrapper.length : clientHeight;

    /**
     * @description Returns pane margin number.
     * @param {HTMLElement} pane
     * @return {number}
     */
    function getPaneMargin(pane) {
        const paneStyles = getComputedStyle(pane);
        const { marginTop, marginBottom } = paneStyles;
        return parseInt(marginTop, 10) + parseInt(marginBottom, 10);
    }

    /**
     * @description Returns pane height minus sticky wrapper height.
     * @param {number} paneHeight
     * @return {number}
     */
    function calcPaneElementsHeight(paneHeight) {
        return paneHeight - stickyWrapperHeight;
    }

    /**
     * @description Returns calculation of sticky wrapper height.
     * @returns {number}
     */
    function calcStickyWrapperHeight() {
        if (stickyWrapper[0].parentElement.id === 'left-pane') {
            const paneMargin = getPaneMargin(leftPane);
            const paneElementsHeight = calcPaneElementsHeight(leftPaneHeight);
            const resultDifference = rightPaneHeight - paneElementsHeight - paneMargin;
            return resultDifference < 600 ? 600 : resultDifference;
        }

        const paneMargin = getPaneMargin(rightPane);
        const paneElementsHeight = calcPaneElementsHeight(rightPaneHeight);
        const resultDifference =
            stickyWrapper.length > 1
                ? (leftPaneHeight - paneElementsHeight - paneMargin) / stickyWrapper.length
                : leftPaneHeight - paneElementsHeight - paneMargin;
        return resultDifference < 600 ? 600 : resultDifference;
    }

    /**
     * @description Set sticky wrapper height.
     */
    function setStickyWrapperHeight() {
        stickyWrapper[0].style.height = `${calcStickyWrapperHeight()}px`;
    }

    setStickyWrapperHeight();
};
