import { bleskHeader } from '@default/scripts/modules/blesk-header';
import { makeStickyHeight } from './utils/make-sticky-height';

/**
 * Blesk Hlavicka
 */
bleskHeader();

/**
 * Calculate sticky wrapper height for skyscaper ads.
 * Timeout waiting for page to render - fake domLoaded.
 * Can be rewritten in relation to the new advertising script - with customEvents.
 */
setTimeout(() => {
    makeStickyHeight();

    // MutationObserver tracks changes in the previous sticky wrapper.
    if ('MutationObserver' in window) {
        const stickyWrapper = document.querySelector('#right-pane .sticky-wrapper');
        if (stickyWrapper) {
            const observer = new MutationObserver(makeStickyHeight);
            observer.observe(stickyWrapper, { attributes: true });
        }
    }
}, 10000);

/** Anketa */
const dynamicEnquiry = async () => {
    const enquiryElements = document.querySelectorAll('.enquiry-bars');

    if (enquiryElements && enquiryElements.length) {
        const { enquiryBars } = await import(
            /* webpackChunkName: 'chunk-enquiry' */
            '@default/scripts/modules/enquiryBars/enquiryBars'
        );

        enquiryBars(enquiryElements);
    }
};
const onlineReportFilters = async () => {
    const allFilterBtn = document.querySelector('.rd_main-filter__item[data-filter-type="all"]');

    const seeMoreBtn = document.querySelector('button[see-more-o-report]');
    const scrollToEl = document.querySelector('.rd_online-report__anchor');
    const filters = document.querySelectorAll('.rd_main-filter__item');
    if (allFilterBtn && scrollToEl && filters.length) {
        const { OnlineReportManager } = await import(
            /* webpackChunkName: 'chunk-onlinereport' */
            '@default/scripts/modules/online-report/OnlineReport'
        );
        OnlineReportManager(allFilterBtn, seeMoreBtn, scrollToEl, filters);
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicEnquiry();
    onlineReportFilters();
};

loadDynamicImports();
